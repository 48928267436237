@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import "../node_modules/bootstrap/dist/css/bootstrap.min.css";

body {
  margin: 0;
  font-family: Inter, sans-serif;
}

a {
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}
