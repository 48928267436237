.head-block {
  padding: 120px 0 80px;
  display: flex;
  gap: 40px;
  flex-direction: column;
  border-bottom: 1px solid #DEDEDE;

  .title {
    width: 75%;
  }
  p {
    width: 60%;
    margin-bottom: 0;
  }
}

.about-block {
  display: flex;
  align-items: center;
  padding: 120px 0 60px;
  gap: 80px;

  .about {
    h2, p {
      margin-bottom: 24px;
    }
    .link {
      gap: 8px;
      display: flex;
      align-items: center;
    }
  }
  .use-case-img {
    width: 48%;
  }
}

@media screen and (max-width: 420px) {
  .head-block {
    padding: 60px 0;

    .title, p {
      width: 100%;
    }

    .use-cases {
      flex-wrap: wrap;
      justify-content: center;
    }
  }

  .about-block {
    flex-direction: column;
    padding: 40px 0;
    gap: 32px;

    .about {
      order: 1;
    }
    .use-case-img {
      width: 100%;
      order: 2
    }
  }
}
