.request-modal {
  .title {
    font-size: 26px;
  }

  .secondary {
    color: #545456;
  }

  label {
    font-size: 11px !important;
    font-weight: 500;
    color: #545456;
    margin-bottom: 4px;
  }

  input {
    background: #F7F8FA;
    border: 1px solid #D3D4D6;
    border-radius: 4px;
    outline: 0;
    width: 100%;
    padding: 4px 12px;
    margin-bottom: 4px;
  }

  button {
    width: 100%;
    padding: 6px 8px;
    height: 32px;
    border-radius: 4px;
    font-weight: 600;
    font-size: 14px;
    line-height: 142.86%;
    color: #545456;
    letter-spacing: 0.1px;
    border: 1px solid #D3D4D6;
    background: #FFFFFF;
    outline: 0;
  }

  .primary-button {
    background: #0E4DEC;
    color: #FFFFFF;
    border: 1px solid #0E4DEC;
  }
  .fs-13 {
    font-size: 13px;
  }

  .divider {
    height: 1px;
    width: calc(50% - 45px);
    background: #E4E7EC;
    position: absolute;
    top: 10px;
  }

  .error {
    border: 1px solid red;
  }

  .already-account {
    font-weight: 400;
    font-size: 13px;
    line-height: 138.46%;
    letter-spacing: 0.2px;
    color: #545456;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 0;

    a {
      margin-left: 6px;
    }
  }

  .close {
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
  }

}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px 0;
}
