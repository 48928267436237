.main-info-block {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 80px;
  padding: 120px 0 80px;
  border-bottom: 1px solid #DEDEDE;

  .version {
    font-weight: 500;
  }
  .buttons {
    display: flex;
  }
  .preview {
    width: 100%;
    display: flex;
    justify-content: center;

    video {
      max-width: 100%;
    }
  }
}

.powered-block {
  padding-top: 60px;
  padding-bottom: 80px;
  display: flex;
  flex-direction: column;


  .clients {
    margin-top: 80px;
    margin-bottom: 48px;
    overflow-x: auto;
    justify-content: space-between;

    .icon-container {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 223px;
      height: 84px;
      cursor: pointer;
      transition: 0.3s;
      &:hover {
        background: rgba(0, 0, 0, 0.03);
        transition: 0.3s;
      }
    }
    .active-icon {
      background: #E8EAFD;
      transition: 0s !important;
      &:hover {
        background: rgba(0, 0, 0, 0.03);
        transition: 0s !important;
      }
      border-radius: 8px;
    }
  }
  .client-container {
    display: flex;
    align-items: center;
    margin-bottom: 32px;

    .client-info {
      width: 50%;
      padding-right: 80px;
      .client-title {
        font-weight: 600;
        font-size: 28px;
        line-height: 36px;
        color: #161618;
        margin-bottom: 24px;
        margin-top: 2px;
      }
      .client-description {
        font-weight: 400;
        font-size: 20px;
        line-height: 150%;
        letter-spacing: 0.1px;
        color: #545456;
      }
      .client-date {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        text-transform: uppercase;
        color: #545456;
      }
    }
    img {
      width: 50%;
    }
  }
  .suggestions {
    flex-grow: 0;
    display: flex;
    gap: 20px;

    .suggestion {
      width: 33.3%;
      align-self: stretch;
      flex-grow: 1;
      background: #F1F1F1;
      border-radius: 10px;
      padding: 24px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 4px;
      .suggestion-title {
        margin-bottom: 0;
        color: #0E4DEC;
        font-weight: 600;
        font-size: 28px;
        line-height: 36px;
      }
      .suggestion-description {
        text-align: center;
        font-weight: 400;
        font-size: 18px;
        line-height: 28px;
        color: #545456;
      }
    }
  }
}
.benefits-block {
  padding: 80px 0;
  display: flex;
  align-items: center;
  .benefits {
    width: 50%;
    padding: 40px 68px 40px 0;
    border-right: 1px solid rgba(0, 0, 0, 0.12);

    .benefit {
      margin-top: 50px;
      h4 {
        margin: 0;
      }

      img {
        margin-right: 12px;
      }
      p {
        margin-top: 4px;
      }
    }
  }
  .diagram-block {
    width: 50%;
    padding: 40px 0 40px 68px;
    img {
      width: 100%;
      margin-bottom: 40px;
    }
  }
}

.steps-block {
  padding: 60px 0 120px;
  .steps {
    width: 100%;
    display: flex;
    gap: 20px;
    margin-top: 80px;
    .step {
      position: relative;
      .step-image {
        width: 100%;
        margin-bottom: 32px;
      }
      h4 {
        margin-bottom: 8px;
      }
      p {
        width: 280px;
        margin: 0 auto;
      }
      .arrow-under, .arrow-top, {
        display: block;
        position: absolute;
        right: -65px;
        z-index: 100;
      }
      .arrow-under, {
        top: 260px;
      }
      .arrow-top {
        top: 0;
      }
      .arrow-left, .arrow-right {
        display: none;
      }
    }
  }

}
.tools-block {
  h2 {
    width: 50%;
  }
  .subtitle {
    width: 75%;
  }

  padding-top: 60px;
  gap: 80px;
  display: flex;
  flex-direction: column;

  .tools-row {
    display: flex;
    justify-content: space-between;
    .tools {
      margin-bottom: 40px;
      width: 320px;
      padding: 20px;
      text-align: center;
      img {
        height: 80px;
        margin-bottom: 12px;
      }
      h4 {
        margin-bottom: 12px;
      }
    }
  }
}
.examples-block {
  padding: 60px 0;
  gap: 64px;
  display: flex;
  flex-direction: column;
  .subtitle {
    width: 65%;
  }

  .examples {
    display: flex;
    justify-content: space-between;
    .example {
      width: 33.3%;
      padding: 0 16px;
      position: relative;
      img {
        width: 100%;
        margin-bottom: 24px;
      }
      p {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        letter-spacing: -0.1px;
        color: #545456;
      }
    }
  }
  .use-cases-link {
    display: flex;
    margin: 0 auto;
    align-items: center;
    gap: 8px;
  }
}

@media screen and (max-width: 420px) {
  .main-info-block {
    padding: 60px 0;
    gap: 40px;
    text-align: center;
    flex-direction: column;

    .buttons {
      display: flex;
      width: 100%;
      gap: 12px;
      justify-content: normal;

      button {
        width: calc(50% - 6px);
      }
    }
  }
  .powered-block {
    padding-top: 40px;
    padding-bottom: 40px;
    .clients {
      margin-top: 40px;
      margin-bottom: 32px;
      gap: 16px;

      .icon-container {
        min-width: 223px;
        height: 76px;
      }
    }
    .client-container {
      flex-direction: column;
      gap: 32px;
      .client-info {
        padding-right: 0;
        width: 100%;

        .client-title {
          font-weight: 700;
          font-size: 20px;
          line-height: 150%;
          letter-spacing: 0.1px;
        }
      }
      img {
        width: 100%;
      }
    }
    .suggestions {
      flex-direction: column;
      .suggestion {
        width: 100%;
      }
    }
  }
  .benefits-block {
    padding: 60px 0 40px;
    flex-direction: column;
    align-items: start;

    .benefits {
      width: 100%;
      padding: 0 0 32px;
      border-right: 0;
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    }
    .diagram-block {
      width: 100%;
      padding: 32px 0 0;
      img {
        width: 100%;
        margin-bottom: 32px;
      }
    }
  }
  .steps-block {
    padding: 40px 0;
    .steps {
      width: 100%;
      flex-direction: column;
      gap: 32px;
      margin-top: 40px;
      .step {
        margin: 0 32px;
        .step-image {
          margin-bottom: 12px;
        }
        p {
          width: 100%;
          margin: 0;
        }
        .arrow-under, .arrow-top, {
          display: none;
        }
        .arrow-left, .arrow-right {
          display: block;
          position: absolute;
          z-index: 100;
        }
        .arrow-left {
          bottom: -70px;
          left: -32px;
        }
        .arrow-right {
          bottom: -60px;
          right: -32px;
        }
      }
    }
  }

  .tools-block {
    h2 {
      width: 100%;
    }
    .subtitle {
      width: 100%;
    }

    padding-top: 40px;
    gap: 40px;

    .tools-row {
      display: flex;
      flex-direction: column;
      justify-content: normal;
      .tools {
        margin-bottom: 32px;
        width: 100%;
        padding: 0;
        img {
          height: 80px;
          margin-bottom: 12px;
        }
        h4 {
          margin-bottom: 12px;
        }
      }
    }
  }

  .examples-block {
    padding: 40px 0;
    gap: 40px;

    .subtitle {
      width: 100%;
    }

    .examples {
      gap: 32px;
      flex-direction: column;
      justify-content: normal;
      .example {
        width: 100%;
        padding: 0;
        img {
          width: 100%;
          margin-bottom: 16px;
        }
      }
    }
  }
}
