.button {
  min-width: 100px;
  border-radius: 8px;
  padding: 10px 30px;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.1px;
  font-weight: 600;
}
.primary {
  box-sizing: border-box;

  &-contained {
    border: 0;
    color: white;
    background: #0E4DEC;
  }

  &-text {
    color: #0039D4;
    font-size: 14px;
    border: 1.5px solid #0E4DEC;
    background: transparent;
  }
}

.dark {
  box-sizing: border-box;

  &-contained {
    border: 0;
    color: white;
    background: #161618;
  }

  &-text {
    font-size: 14px;
    border: 1.5px solid rgba(0, 0, 0, 0.1);
    color: #161618;
    background: transparent;
  }
}

.bordered {
  border: 1.5px solid #161618;
}

@media screen and (max-width: 420px) {
  .button {
    font-size: 14px;
    padding: 10px 15px;
  }
}
