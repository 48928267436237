.tabs-container {
  padding: 80px 0;
  .tabs {
    display: flex;
    gap: 16px;
    justify-content: center;
    margin-bottom: 32px;

    .tab-item {
      padding: 8px 12px;
      gap: 10px;
      border-radius: 8px;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.1px;
      color: #545456;
      transition: 0.3s;
      &:hover {
        transition: 0.3s;
        cursor: pointer;
        background: rgba(0, 0, 0, 0.03);
      }
    }
    .selected-item {
      background: #E8EAFD;
      color: #0E4DEC;
      &:hover {
        background: #E8EAFD;
      }
    }
  }

  .tab-content {
    display: flex;
    align-items: center;
    padding: 60px;
    gap: 60px;
    background: #F1F1F1;
    border-radius: 10px;

    h2 {
      margin-bottom: 24px;
    }
    p {
      font-weight: 400;
      font-size: 20px;
      line-height: 150%;
      letter-spacing: 0.1px;
      color: #545456;
      margin-bottom: 24px;
    }

    .check-items {
      display: flex;
      flex-direction: column;
      gap: 12px;
      margin-bottom: 24px;
      div {
        display: flex;
        align-items: center;
        gap: 12px;

        p {
          margin: 0;
        }

        img {
          width: 28px;
          height: 28px;
        }
      }
    }
    .buttons {
      display: flex;
      gap: 12px;
    }

    img {
      width: calc(50% - 30px);
    }
  }
}

@media screen and (max-width: 420px) {
  .tabs-container {
    display: flex;
    flex-direction: column;
    padding: 40px 0;
    .tabs {
      overflow-x: auto;
      overflow-y: hidden;
      justify-content: space-between;

      .tab-item {
        min-width: max-content;
      }
    }

    .tab-content {
      flex-direction: column;
      gap: 32px;
      padding: 24px 16px;

      h2 {
        font-size: 28px;
        font-weight: 600;
      }

      p {
        font-size: 16px;
      }
      .buttons {
        display: grid;
        grid-template-columns: 1fr 1fr;

        .stretch {
          grid-column: 1/3;
        }
      }

      img {
        width: 100%;
      }
    }
  }
}
