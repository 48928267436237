.use-case-head-block {
  padding: 100px 0 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .info {
    width: calc(50% - 100px);
    h5 {
      margin-bottom: 16px;
    }
    h1 {
      margin-bottom: 40px;
    }
    p {
      margin-bottom: 0;
    }
  }

  img {
    width: 49%;
  }
}
@media screen and (max-width: 420px) {
  .use-case-head-block {
    padding: 60px 0;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    .info {
      width: 100%;
      margin-bottom: 40px;
      h5 {
        margin-bottom: 12px;
      }
      h1 {
        font-size: 36px;
        margin-bottom: 24px;
      }
    }

    img {
      width: 100%;
    }
  }
}
