footer {
  padding: 60px 80px 48px;
  background: #090A18;

  ul {
    display: flex;
    gap: 32px;
    margin-top: 24px;
    margin-bottom: 60px;

    li {
      color: #FFFFFF;
      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }

  }
  .terms {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    padding-top: 32px;
    display: flex;
    justify-content: space-between;
    color: rgba(255, 255, 255, 0.6);
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.1px;

    .socials {
      display: flex;
      gap: 20px;
      margin-bottom: 32px;
      cursor: pointer;
    }

    .links {
      display: flex;
      gap: 20px;
      margin-bottom: 8px;

      span:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }

  @media screen and (max-width: 420px) {
    padding: 60px 0 48px;
    ul {
      margin-bottom: 28px;
      flex-wrap: wrap;
      gap: normal;

      li {
        width: 33.3%;
        text-align: center;
        margin-bottom: 32px;
      }
    }
    .terms {
      padding-top: 20px;
      display: flex;
      justify-content: normal;
      align-items: center;
      flex-direction: column;

      .rights {
        order: 3;
      }
      .socials {
        order: 1;
        display: flex;
        gap: 20px;
      }

      .links {
        order: 2;
        gap: 20px;
      }
    }
  }
}
