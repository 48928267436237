header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  z-index: 998;
  background: #F8F8F8;
  width: 100%;
  .header {
    height: 72px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #161618;
    font-weight: 500;
    font-size: 14px;

    #logo {
      cursor: pointer;
    }
    ul  {
      list-style: none;
      margin-bottom: 0;
      gap: 36px;
      padding: 0;
      li {
        &:hover {
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }

    .open {
      right: 0;
      transition: 0.3s;
    }
    .close {
      right: -60%;
      transition: 0.3s;
    }

  }
  .menu {
    display: none;
  }
  @media screen and (max-width: 420px) {

    .header {
      height: auto;
      padding-top: 12px;
      padding-bottom: 12px;
      z-index: 10;
      background: #F8F8F8;
      position: absolute;

      #logo {
        margin-left: 4px;
      }
    }
    .open {
      transition: 0.3s !important;
      bottom: -100vh !important;
    }
    .menu {
      z-index: 9;
      background: #F8F8F8;
      display: flex;
      flex-direction: column;
      position: absolute;
      width: 100%;
      height: calc(100vh - 64px);
      bottom: -65px;
      transition: 0.3s;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      ul  {
        list-style: none;
        gap: 20px;
        display: flex;
        flex-direction: column;
        padding: 16px;
        margin-bottom: 16px;
        li {
          font-weight: 500;
          font-size: 18px;
          line-height: 28px;
          color: #161618;
          &:focus {
            text-decoration: underline;
          }
        }
      }
      .socials {
        padding: 0 16px;
        gap: 20px;
        display: flex;
        align-items: flex-start;
        img {
          fill: rgba(0, 0, 0, 0.6);
        }
      }
      .buttons {
        margin-top: auto;
        padding: 16px;
        gap: 12px;
        display: flex;
        flex-direction: column;
        width: 100%;
      }
    }
  }

}
