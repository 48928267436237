.assay {
  width: 100%;
  margin-bottom: 120px;
  background-color: #2959D5;
  background-image: url(../../icons/macbook.png);
  background-position: bottom right;
  background-size: 45% auto;
  background-repeat: no-repeat;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  padding: 60px;

  &-form {
    width: 50%;

    h3 {
      font-weight: 600;
      font-size: 28px;
      line-height: 36px;
      color: #FFFFFF;
      margin-bottom: 12px;
    }
    input {
      background: url(../../icons/at-icon.svg) #FFFFFF no-repeat 12px 10px;
      padding: 10px 12px 10px 40px;
      outline: none;
      width: 295px;
      height: 44px;
      border: 1px solid #E8EAFD;
      border-radius: 8px;
      font-size: 16px;
      line-height: 150%;
      letter-spacing: 0.1px;
      margin-right: 12px;
    }
    .form-subtitle {
      font-weight: 400;
      font-size: 20px;
      line-height: 150%;
      letter-spacing: 0.1px;
      color: rgba(255, 255, 255, 0.72);
      margin-bottom: 40px;
    }
    .support-text {
      color: rgba(255, 255, 255, 0.5);
    }
  }
}
@media screen and (max-width: 420px) {
  .assay {
    margin-bottom: 60px;
    margin-top: 40px;
    background-image: url(../../icons/macbook-small.png);
    background-size: 100% auto;
    padding: 20px 20px 320px;

    &-form {
      width: 100%;
      input {
        margin-bottom: 12px;
        width: 100%;
        margin-right: 0;
      }
    }
  }

}
