.App {
  background: #F8F8F8;
  padding-top: 72px;
  h1 {
    font-size: 60px;
    line-height: 120%;
    letter-spacing: -1px;
    font-weight: bold;
    color: #161618
  }
  h2 {
    font-size: 36px;
    color: #161618;
    line-height: 114%;
    font-weight: 700;
    margin-bottom: 20px;
  }
  h4 {
    font-weight: 700;
    font-size: 20px;
    line-height: 150%;
    color: #161618;
  }
  h5 {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #0E4DEC;
    text-transform: uppercase;
    margin-bottom: 16px;
  }
  .subtitle {
    width: 70%;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0.1px;
    color: #545456;
  }
  .support-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 142.86%;
    text-align: center;
    letter-spacing: 0.1px;
    color: #545456;
  }

  .paragraph {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.1px;
    color: #545456;
  }
  @media screen and (max-width: 420px) {
    padding-top: 64px;
    h1 {
      font-weight: 700;
      font-size: 36px;
      line-height: 114%;
    }
    h2 {
      width: 100%;
      font-weight: 600;
      font-size: 28px;
      line-height: 36px;
    }
    .subtitle {
      width: 100%;
    }
  }
}
