.create-steps-container {
  padding: 80px 0 120px;

  h2 {
    width: 50%;
    margin: 0 auto 80px;
    text-align: center;
  }

  .steps {
    display: flex;
    flex-wrap: wrap;
    gap: 20px 20px;
    justify-content: center;

    .step {
      width: 280px;
      display: flex;
      flex-direction: column;

      .arrow-left, .arrow-right {
        display: none;
      }
      .step-image {
        width: 100%;
        margin-bottom: 32px;
      }

      h4 {
        text-align: center;
        margin-bottom: 8px;
      }

      p {
        font-weight: 400;
        font-size: 14px;
        line-height: 142.86%;
        text-align: center;
        letter-spacing: 0.1px;
        color: #545456;
        margin: 0;
      }

      .external-link {
        display: flex;
        align-items: center;
        margin: 0 auto;
        gap: 8px;
      }
    }

    .break {
      flex-basis: 100%;
      height: 0;
    }
  }

}

@media screen and (max-width: 420px) {
  .create-steps-container {
    padding: 40px 0;

    h2 {
      width: 100%;
      margin: 0 0 40px;
    }

    .steps {
      gap: 32px;

      .step {
        position: relative;
        .step-image {
          margin-bottom: 16px;
        }
        .arrow-left, .arrow-right {
          display: block;
          position: absolute;
          width: 65px;
          top: -160px;
        }
        .arrow-left {
          left: -30px;
        }
        .arrow-right {
          right: -30px;
        }
      }
      .break {
        display: none;
      }
    }
  }
}
